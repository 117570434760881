<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            label="자재명"
            name="materialName"
            placeholder="자재명"
            v-model="searchParam.materialName">
          </c-text>
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            label="자재코드"
            name="materialCd"
            placeholder="자재코드"
            v-model="searchParam.materialCd">
          </c-text>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 화학자재여부 -->
          <c-select
            :comboItems="chemYnItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="chemMaterialFlag"
            label="화학자재여부"
            v-model="searchParam.chemMaterialFlag"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="materialTable"
          title="자재목록"
          tableId="materialTable"
          :columns="grid.columns"
          :data="grid.data"
          :columnSetting="false"
          selection="multiple"
          rowKey="materialCd"
          :editable="editable"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="추가" v-if="editable" icon="add" @btnClicked="addrow" />
              <c-btn label="삭제" v-if="editable" icon="remove" @btnClicked="removeRow" />
              <c-btn label="저장" v-if="editable" icon="save" @btnClicked="saveMaterial" />
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <q-dialog v-model="prompt" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">자재코드</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input dense v-model="hiddenMaterialCd" autofocus @keyup.enter="setMaterialCd" />
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="취소" @click="cancelMaterialCd" />
          <q-btn flat label="추가" @click="setMaterialCd" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'material-manage',
  data() {
    return {
      prompt: false,
      hiddenMaterialCd: '',
      grid: {
        columns: [
          {
            name: 'materialCd',
            field: 'materialCd',
            label: '자재코드',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'materialName',
            field: 'materialName',
            label: '자재명',
            align: 'left',
            type: 'text',
            sortable: true,
          },
          {
            name: 'materialNameEn',
            field: 'materialNameEn',
            label: '자재명(ENG)',
            align: 'left',
            type: 'text',
            sortable: true,
          },
          {
            name: 'chemMaterialFlag',
            field: 'chemMaterialFlag',
            label: '화학자재여부',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'materialGroupCd',
            field: 'materialGroupCd',
            label: '자재그룹 코드',
            align: 'center',
            type: 'text',
            sortable: true,
          },
          {
            name: 'materialGroupName',
            field: 'materialGroupName',
            label: '자재그룹 명',
            align: 'center',
            type: 'text',
            sortable: true,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:80px',
            sortable: true,
            type: 'check',
            true: 'Y',
            false: 'N'
          },
        ],
        data: [],
        height: '362px'
      },
      searchParam: {
        useFlag: 'Y',
        materialCd: '',
        materialName: '',
        chemMaterialFlag: null
      },
      chemYnItems: [
        { code: 'Y', codeName: '화학자재' },
        { code: 'N', codeName: '일반자재' },
      ],
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      listUrl: '',
      checkUrl: '',
      saveUrl: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.mam.material.list.url;
      this.checkUrl = selectConfig.mdm.mam.material.check.url;
      this.saveUrl = transactionConfig.mdm.mam.material.save.url;
      this.deleteUrl = transactionConfig.mdm.mam.material.delete.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addrow() {
      this.prompt = true;
    },
    cancelMaterialCd() {
      this.hiddenMaterialCd = '';
      this.prompt = false;
    },
    setMaterialCd() {
      this.prompt = false;
      this.$http.url = this.$format(this.checkUrl, this.hiddenMaterialCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        if (_result.data === 0) {
          this.grid.data.splice(0, 0, {
            editFlag: 'C',
            materialCd: this.hiddenMaterialCd,
            materialName: '',
            materialNameEn: '',
            chemMaterialFlag: 'N',
            materialGroupCd: '',
            materialGroupName: '',
            materialTypeCd: '',
            useFlag: 'Y',
            regUserId: this.$store.getters.user.userId,
          });
          this.hiddenMaterialCd = '';
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message:
            '동일한 자재코드가 존재합니다.',
            type: 'warning', // success / info / warning / error
          });
          this.hiddenMaterialCd = '';
        }
      },);

    },
    saveMaterial() {
      let checkItem = ['materialName']
      let isConti = true;
      this.$_.forEach(this.grid.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [자재명]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.grid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getList();
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: '변경된 데이터가 없습니다.', /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    removeRow() {
      let selectData = this.$refs['materialTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['materialTable'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
